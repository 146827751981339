import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { loadableReady } from '@loadable/component';
import Wrapper from './src/components/wrapper'

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfOYD0gAAAAAA0o31Jxuj7_15EeuVboRV22DDox">
      {element}
    </GoogleReCaptchaProvider>
  )
}

// Wraps every page in a components
export const wrapPageElement = ({ element, props }) => {
  return <Wrapper {...props}>{element}</Wrapper>
}

// lazy load
export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback)
    })
  }
}