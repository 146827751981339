exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-es-areas-de-bufete-index-js": () => import("./../../../src/pages/es/areas-de-bufete/index.js" /* webpackChunkName: "component---src-pages-es-areas-de-bufete-index-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-fx-form-js": () => import("./../../../src/pages/fx-form.js" /* webpackChunkName: "component---src-pages-fx-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-dir-mdx-slug-js": () => import("./../../../src/pages/{mdx.frontmatter__dir}/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-mdx-frontmatter-dir-mdx-slug-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-practice-areas-index-js": () => import("./../../../src/pages/practice-areas/index.js" /* webpackChunkName: "component---src-pages-practice-areas-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-results-index-js": () => import("./../../../src/pages/results/index.js" /* webpackChunkName: "component---src-pages-results-index-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

